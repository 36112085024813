import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useMissionTask } from "../../../../providers/MissionTaskProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import DateAndTimePicker from "../../../common/inputs/DateAndTimePicker";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import { Grid, Table, TableBody, TableCell, TableRow, Typography, Button } from "@mui/material";
import { MissionEnumsLists, TaskEnums } from "../../../../common/constants";
import { Phone as PhoneIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { FormatLocation } from "../../../../common/maphelpers";
import moment from "moment/moment";

const useStyles = makeStyles()(
  (theme) => ({
    table: {
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        paddingLeft: 0,
        verticalAlign: "top"
      }
    }
  })
);

export default function MissionTaskDetailsCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { mission, missionTask, taskState, taskPath } = useMissionTask();

  const { actors, target, details, created } = mission;
  const estimatedArrival = useEntityField(`${taskPath}.execution.estimatedArrival`, Defaults.String);
  const targetType = target.type;
  const targetDetails = targetType === 1 ? target.carDetails : (targetType === 2 ? target.boatDetails : null);

  const requester = actors.ownerIsNotRequester ? actors.requester : actors.owner;
  const hasValidContactPerson = actors.hasContactPerson && !!actors.contactPerson.phoneNumber;

  const waypoints = missionTask?.route?.waypoints || [];
  const location = waypoints[0];
  const destination = waypoints[waypoints?.length - 1 || 0]

  return (
    <ToggleSurface
      xs={12}
    >
      <Grid item container spacing={1}>

        {taskState < TaskEnums.State.Arrived ? (
          <Grid item xs={12}>
            <DateAndTimePicker
              label={t("taskDetailsCard:eta")}
              value={estimatedArrival.value}
              onChange={estimatedArrival.onChange}
              includeTime
              fromNow
            />
          </Grid>
        ) : null}


        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:type")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {t(`mission:enums:type:${MissionEnumsLists.Type[details.type]}`)}{!!details?.cause?.name ? ` - ${details.cause.name}` : ""}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:created")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {moment(created).format("lll")}
                  </Typography>
                </TableCell>
              </TableRow>

              {
                !!targetDetails ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:target")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {t(`mission:enums:targetType:${MissionEnumsLists.TargetType[targetType]}`)}, {targetDetails?.make || "-"} {targetDetails?.model || "-"}, {targetDetails?.registration || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:details")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {details?.description || "-"}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:comment")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {missionTask?.comment || "-"}
                  </Typography>
                </TableCell>
              </TableRow>

              {taskState < TaskEnums.State.Arrived ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:location")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {location?.address ? FormatLocation(location) : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:locationDescription")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {missionTask?.description || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>

                </>
              ) : null}

              {
                missionTask?.route?.waypoints?.length > 1 && taskState < TaskEnums.State.Arrived ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:transport")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {destination?.address ? FormatLocation(destination) : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:requester")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {requester?.name || "-"}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:requesterPhoneNumber")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {requester?.phoneNumber || "-"}
                  </Typography>
                </TableCell>
              </TableRow>

              {
                hasValidContactPerson ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:contactPerson")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {actors?.contactPerson.name || "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:contactPersonPhoneNumber")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {actors?.contactPerson.phoneNumber || "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>

                  </>
                ) : null
              }

              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:customerWaiting")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {t(`mission:enums:locationState:${MissionEnumsLists.LocationState[missionTask?.locationState || 0]}`)}
                  </Typography>
                </TableCell>
              </TableRow>

              {
                missionTask?.locationState === 1 ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="textSecondary">{t("taskDetailsCard:keyPlacement")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {missionTask?.keyPlacement || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              <TableRow>
                <TableCell colSpan="2">
                  <NavLink to={`./mission/${missionTask?.number || 0}`}>
                    <Button fullWidth variant="text" size="large" endIcon={<ChevronRightIcon />}>
                      {t("taskDetailsCard:showMore")}
                    </Button>
                  </NavLink>
                </TableCell>
              </TableRow>

              {
                !!requester.phoneNumber && taskState < TaskEnums.State.Arrived ? (
                  <TableRow>
                    <TableCell colSpan="2">
                      <Button fullWidth variant="contained" size="large" color="inherit" startIcon={<PhoneIcon />} href={`tel:${requester.phoneNumber}`}>
                        {t("taskDetailsCard:callRequester")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null
              }

              {
                !!actors.contactPerson.phoneNumber && hasValidContactPerson && taskState < TaskEnums.State.Arrived ? (
                  <TableRow>
                    <TableCell colSpan="2">
                      <Button fullWidth variant="contained" size="large" color="inherit" startIcon={<PhoneIcon />} href={`tel:${actors.contactPerson.phoneNumber}`}>
                        {t("taskDetailsCard:callContactPerson")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null
              }

            </TableBody>
          </Table>
        </Grid>

      </Grid>
    </ToggleSurface>
  );
}
