import { useCallback } from "react";
import { Grid, TextField, IconButton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMissionRoute } from "../../../providers/MissionProvider";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import { useMissionTask } from "../../../providers/MissionTaskProvider";
import TaskMapSection from "../map/TaskMapSection";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import HorizontalSelector from "../inputs/HorizontalSelector";
import LocationPicker from "../inputs/LocationPicker";
import { CancelOutlined as CancelIcon, Add as AddIcon } from "@mui/icons-material";

const onDragStart = () => {
  if (window.navigator.vibrate) {
    window.navigator.vibrate(100);
  }
}

export default function AssistanceSection() {
  const { t } = useTranslation();
  const { taskPath } = useMissionTask();
  const { route, routeRevision, reorder, onChange: onChangeRoute } = useMissionRoute(taskPath);

  const resolvedAtLocation = useEntityField(`${taskPath}.resolvedAtLocation`, Defaults.Boolean);
  const comment = useEntityField(`${taskPath}.comment`, Defaults.String);

  const onResolvedAtLocation = useCallback((idx) => {
    const resolved = idx === 1;
    resolvedAtLocation.onChange(null, resolved);
    if (resolved) {
      onChangeRoute(null, route.locations.filter((_, i) => i === 0).map(t => t.getData()));
    }
  }, [resolvedAtLocation, onChangeRoute, route, route.locations]);

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={reorder}>
      <Droppable droppableId="locations">
        {(droppableProvided) => (
          <Grid item container spacing={1} ref={droppableProvided.innerRef} >

            <Grid item xs={12}>
              <HorizontalSelector items={["Transport", "Fikses på plass"]} value={resolvedAtLocation.value ? 1 : 0} onChange={onResolvedAtLocation} />
            </Grid>

            <Grid item xs={12}>
              <TaskMapSection
                routeRevision={routeRevision}
                route={route}
                path={taskPath}
                fitScreenButton
              />
            </Grid>

            {route.locations.map((location, idx) => (
              <Draggable draggableId={location.id + idx} index={idx} key={location.id + idx}>
                {(provided) => (
                  <Grid
                    container item xs={12}
                    ref={provided.innerRef}
                    className="draggable-location"
                    {...provided.draggableProps}
                  >
                    <LocationPicker
                      picker
                      draggable
                      label={t(idx === 0 ? "mission:cards:tasks:salvageTask:missionLocation" : idx === route.locations.length - 1 ? "mission:cards:tasks:salvageTask:transportTo" : "mission:cards:tasks:salvageTask:waypoint")}
                      value={location}
                      onChange={location.replaceFn}
                      dragHandleProps={provided.dragHandleProps}
                      enableContactSearching
                    />
                    {
                      idx !== 0 ? (
                        <IconButton onClick={location.removeFn}>
                          <CancelIcon />
                        </IconButton>
                      ) : null
                    }
                  </Grid>
                )}
              </Draggable>
            ))}

            {droppableProvided.placeholder}

            <Grid item xs={12}>
              <Button size="medium" onClick={route.addEmptyFn} startIcon={<AddIcon />} disabled={resolvedAtLocation.value}>
                {t(route.locations.length === 1 ? "mission:cards:tasks:salvageTask:addTransportButton" : "mission:cards:tasks:salvageTask:addWaypointButton")}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t("mission:cards:tasks:salvageTask:comment")}
                variant="filled"
                multiline
                fullWidth
                size="small"
                minRows={4}
                value={comment.value}
                onChange={comment.onChange}
              />
            </Grid>

          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}
