import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import SimpleTable from "../../../common/tables/SimpleTable";
import { ToggleSurface } from "../../../common/core/Surface";
import { Button, Grid, Typography } from "@mui/material";
import { useDevice } from "../../../../providers/DeviceProvider";
import { MissionEnums } from "../../../../common/constants";
import { AllTasksCompleted, AssumeStorageState } from "../../../../common/mission";
import { makeStyles } from "tss-react/mui";
import ConfirmDialog from "../../../common/inputs/ConfirmDialog";
import { useInvoiceExport } from "../../../../hooks/useInvoiceExport";
import MissingExportSettingsWarning from "../../../common/billing/MissingExportSettingsWarning";

const useStyles = makeStyles()(
  (theme) => ({
    approveAllSurface: {
      border: `1px solid ${theme.palette.divider}`
    },
  })
);

export default function ReadyForInvoicingSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();
  const { slim } = useDevice();
  const { classes } = useStyles();

  const { orders, loading, exportAction, exportAll, hasExportSettings } = useInvoiceExport();
  const [selectionModel, setSelectionModel] = useState([]);
  const showLoading = loading;


  /*
  useEffect(() => {
    if (loading) {
      return () => {
        setSelectionModel(orders.map(t => t.id));
      }
    }
  }, [loading, orders]);
*/

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("readyForInvoicing:fieldNumber"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("readyForInvoicing:fieldMission"),
        flex: 0
      },
      {
        field: "storage",
        headerName: t("readyForInvoicing:fieldStorageState"),
        valueGetter: (params) => AssumeStorageState(params.row.mission),
        valueFormatter: (params) =>
          params.value === MissionEnums.StorageState.None ? t("readyForInvoicing:noStorage") :
            params.value === MissionEnums.StorageState.Stored ?
              t("readyForInvoicing:storageNotCompleted") :
              t("readyForInvoicing:storageCompleted"),
      },
      {
        field: "taskState",
        type: "boolean",
        headerName: t("readyForInvoicing:fieldTaskState"),
        valueGetter: (params) => AllTasksCompleted(params.row.mission),
        renderCell: (params) => params.value ? t("readyForInvoicing:tasksCompleted") : t("readyForInvoicing:tasksNotCompleted"),
        flex: 1,
        minWidth: 120
      },
      {
        field: "missionCreated",
        type: "date",
        headerName: t("readyForInvoicing:fieldMissionCreated"),
        valueGetter: (params) => params.row.mission?.created,
        valueFormatter: (params) => !params.value ? "-" : moment(params.value).format("ll"),
        flex: 1,
        minWidth: 120
      },
      {
        field: "customer.name",
        headerName: t("readyForInvoicing:fieldCustomer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("readyForInvoicing:fieldSumTotal"),
        valueGetter: (params) => params.row.totalSum?.toNumber() || 0,
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.totalSum)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("readyForInvoicing:fieldDeductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./ready/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  const onExportAll = useCallback(async () => {
    const { success, exportId } = await exportAll(selectionModel);
    if (!!success) {
      navigate(!tabId ? `./journals/${exportId}` : `../journals/${exportId}`, { relative: "path" });
    }
  }, [exportAll, navigate, tabId, selectionModel]);

  return (
    <>
      {
        orders.length !== 0 ? (
          <ToggleSurface surface xl={4} lg={5} md={6} sm={8} xs={12} className={slim ? classes.exportAllSurface : undefined}>
            <Grid item container xs={12} spacing={1}>

              <Grid item xs={12}>
                <Typography variant="subtitle1">{t("readyForInvoicing:exportAllTitle")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  {t("readyForInvoicing:exportAllDescriptionPart1")} {selectionModel.length} {t("readyForInvoicing:exportAllDescriptionPart2")}
                </Typography>
              </Grid>

              {
                !hasExportSettings ? (
                  <MissingExportSettingsWarning />
                ) : null
              }

              <Grid item xs={12} container justifyContent="flex-end">
                <Button variant="contained" disabled={showLoading || !hasExportSettings || selectionModel.length === 0} onClick={onExportAll}>{t("readyForInvoicing:exportAllButton")}</Button>
              </Grid>
            </Grid>
          </ToggleSurface>
        ) : null
      }

      <ToggleSurface>
        <Grid item xs={12}>
          <SimpleTable
            tableId="readyforinvoicing"
            onRowClick={onRowClick}
            columns={columns}
            rows={orders}
            loading={showLoading}
            emptyStateTitle={t("readyForInvoicing:emptyStateTitle")}
            emptyStateDescription={t("readyForInvoicing:emptyStateDescription")}
            checkboxSelection
            disablePaging
            onSelectionModelChange={setSelectionModel}
            selectionModel={selectionModel}
            xs={12}
          />
        </Grid>
      </ToggleSurface>


      <ConfirmDialog
        task={exportAction}
        title={t("readyForInvoicing:exportAllDialog:title")}
        description={t("readyForInvoicing:exportAllDialog:description")}
        confirmText={t("readyForInvoicing:exportAllDialog:confirmText")}
      />
    </>
  );
}

