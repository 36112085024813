import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import EntityTable from "../../../common/tables/EntityTable";

export default function CashJournalEntriesSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "exportNumber",
        type: "number",
        headerName: t("cashJournal:fieldNumber"),
        flex: 0
      },
      {
        field: "orderNumber",
        type: "number",
        headerName: t("cashJournal:fieldOrderNumber"),
        flex: 0
      },
      {
        field: "row",
        type: "number",
        headerName: t("cashJournal:fieldRow"),
        flex: 0
      },
      {
        field: "item.quantity",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.item?.quantity || "1"),
        valueFormatter: params => FixNumber(params.value),
        headerName: t("cashJournal:fieldQuantity"),
        flex: 0
      },
      {
        field: "unitPrice.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.unitPrice?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.unitPrice?.value)} ${getCurrencyShorthand(t, params.row.unitPrice?.currency)}`,
        headerName: t("cashJournal:fieldUnitPrice"),
        minWidth: 120,
      },
      {
        field: "costPrice.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.costPrice?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.costPrice?.value)} ${getCurrencyShorthand(t, params.row.costPrice?.currency)}`,
        headerName: t("cashJournal:fieldCostPrice"),
        minWidth: 120,
      },
      {
        field: "item.code",
        valueGetter: (params) => params.row.item?.code || "-",
        headerName: t("cashJournal:fieldProductCode"),
        flex: 0
      },
      {
        field: "item.name",
        valueGetter: (params) => params.row.item?.name || "-",
        headerName: t("cashJournal:fieldProductName"),
        flex: 0
      },
      {
        field: "sender.name",
        valueGetter: (params) => params.row.sender?.name || "-",
        headerName: t("cashJournal:fieldSender"),
        flex: 1,
      },
      {
        field: "confirmedBy.name",
        valueGetter: (params) => params.row.confirmedBy?.name || "-",
        headerName: t("cashJournal:fieldConfirmedBy"),
        flex: 1,
      },
      {
        field: "account",
        valueGetter: (params) => params.row.account || "-",
        headerName: t("cashJournal:fieldAccount"),
        flex: 1,
      },
      {
        field: "item.productGroup.vatCode.code",
        valueGetter: (params) => params.row.item?.productGroup?.vatCode?.code|| "-",
        headerName: t("cashJournal:fieldVatCode"),
        flex: 1,
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.row.exportId}`, { relative: "path" });
  }, [navigate]);

  return (
    <EntityTable
      tableId="cash_journal"
      type="CashJournal"
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("cashJournal:emptyStateTitle")}
      emptyStateDescription={t("cashJournal:emptyStateDescription")}
      serverSidePaging
      xs={12}
    />
  );
}

/*

*/