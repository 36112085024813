import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { Compare } from "@emberly/zenith-client";
import moment from "moment/moment";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import EntityTable from "../../../common/tables/EntityTable";

const Query = { name: "approved_settlements", path: "approved", comparer: Compare.EQ, value: true };

export default function ApprovedCashSettlementsSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("approvedCashSettlements:fieldNumber"),
        flex: 0
      },
      {
        field: "created",
        type: "date",
        headerName: t("approvedCashSettlements:fieldSettledDate"),
        valueGetter: (params) => params.row.created,
        valueFormatter: (params) => moment(params.value).format("l"),
        minWidth: 120
      },
      {
        field: "sender.name",
        headerName: t("approvedCashSettlements:fieldSender"),
        valueGetter: (params) => params.row.sender.name || "-",
        minWidth: 200
      },
      {
        field: "approvedDate",
        type: "date",
        headerName: t("approvedCashSettlements:fieldApprovedDate"),
        valueGetter: (params) => params.row.approvedDate,
        valueFormatter: (params) => moment(params.value).format("l"),
        minWidth: 120
      },
      {
        field: "approvedBy.name",
        headerName: t("approvedCashSettlements:fieldApprovedBy"),
        valueGetter: (params) => params.row.approvedBy?.name || "-",
        minWidth: 200
      },
      {
        field: "cashSum.value",
        type: "number",
        entityType: "decimal",
        headerName: t("approvedCashSettlements:fieldSumCash"),
        valueGetter: (params) => Number(params.row.cashSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.cashSum.value)} ${getCurrencyShorthand(t, params.row.cashSum.currency)}`,
        minWidth: 120
      },
      {
        field: "cardSum.value",
        type: "number",
        entityType: "decimal",
        headerName: t("approvedCashSettlements:fieldSumCard"),
        valueGetter: (params) => Number(params.row.cardSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.cardSum.value)} ${getCurrencyShorthand(t, params.row.cardSum.currency)}`,
        minWidth: 120
      },
      {
        field: "mobilePaymentSum.value",
        type: "number",
        entityType: "decimal",
        headerName: t("approvedCashSettlements:fieldSumApp"),
        valueGetter: (params) => Number(params.row.mobilePaymentSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.mobilePaymentSum.value)} ${getCurrencyShorthand(t, params.row.mobilePaymentSum.currency)}`,
        minWidth: 120
      },
      {
        field: "totalSum.value",
        type: "number",
        entityType: "decimal",
        headerName: t("approvedCashSettlements:fieldSumTotal"),
        valueGetter: (params) => Number(params.row.totalSum.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.totalSum.value)} ${getCurrencyShorthand(t, params.row.totalSum.currency)}`,
        minWidth: 120
      },

    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./approval/${params.id}` : `./${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  return (
    <EntityTable
      tableId="approved_cash_settlements"
      type="CashSettlement"
      filter={Query}
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("approvedCashSettlements:emptyStateTitle")}
      emptyStateDescription={t("approvedCashSettlements:emptyStateDescription")}
      serverSidePaging
      xs={12}
    />
  );
}
