import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import moment from "moment/moment";
import { OrderEnumsLists } from "../../../../../common/constants";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      },
    },
  })
);

export default function InvoiceReadyInformationCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { invoice, comment, reference } = entity.payment;
  const customer = entity.customer;
  const billing = customer.billing;

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">

        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("order:invoiceInformation:title")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:state")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{t(`order:enums:invoiceState:${OrderEnumsLists.InvoiceState[invoice.state]}`)}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:date")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{moment(invoice.stateChangedDate).format("lll")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:person")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{entity.payment.sender.name}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">{t("order:invoiceInformation:invoice")}</Typography>
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:orderDate")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{moment(invoice.orderDate).format("ll")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:address")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{billing.address}, {billing.postNumber} {billing.area}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:email")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{billing.email || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:contactPerson")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{invoice.contactPerson?.name || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:organizationNumber")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{customer.organizationNumber || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:customerNumber")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{customer.customerNumber || "-"}</Typography>
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:comment")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{comment || "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:invoiceInformation:reference")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{reference || "-"}</Typography>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>

      </Grid>
    </div>
  );
}
