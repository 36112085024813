import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import Surface from "../../../common/core/Surface";
import { Add as AddIcon } from "@mui/icons-material";
import { useDevice } from "../../../../providers/DeviceProvider";


const useStyles = makeStyles()(
  (theme) => ({
    outer: {
      minHeight: "100%",
      "& .MuiCollapse-wrapper": {
        minHeight: "100%"
      }
    },
    root: {
      minWidth: "220px",
      minHeight: "100%",
      width: "auto",
      maxWidth: `calc(100vw - ${theme.spacing(4)})`
    },
    rootSlim: {
      border: `1px solid ${theme.palette.divider}`,
      width: "100%"
    },
    description: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    icon: {
      marginRight: theme.spacing(2)
    },
  })
);

export default function IntegrationCard(props) {
  const { icon, title, description, existing, onClick, available } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { slim } = useDevice();

  return (
    <Surface className={slim ? classes.rootSlim : classes.root} outerClassName={classes.outer} collapseClassName={classes.outer}>

      <Grid item container xs={12} alignItems="center" spacing={2}>
        <Grid item>
          <img src={`/assets/integrations/icons/${icon}`} width="36px" height="auto" />
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle1">{title} {title === "Tripletex" || title === "PowerOfficeGo" ? "(Beta)" : ""}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" className={classes.description}>{description}</Typography>
      </Grid>

      <Grid item xs={12} container justifyContent="flex-end">
        {
          existing ? (
            <Button color="secondary" onClick={onClick}>{t("integrationCard:edit")}</Button>
          ) : (
            <Button color="secondary" startIcon={<AddIcon color="inherit" />} onClick={onClick} disabled={!available}>{available ? t("integrationCard:add") : "Kommer snart"}</Button>
          )
        }
      </Grid>

    </Surface>
  )
}