
function isProd() {
  try {
    return document.location.hostname.startsWith("go.");
  } catch {
    return false;
  }
}

const prod = isProd();

const config = {
  version: "0.0.70_TEST14",  
  basename: "/",
  defaultPath: "/dashboard/default",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  auth: prod ? {
    clientId: "sksgxqk0fIMu7Weo9pKxAd3TRUnoLGTs"
  } : {
    clientId: "GOAyvuNuazNcSXiVMlwPtQ76wjzLYXzy"
  },
  vapid: prod ? {
    publicKey: "BNOdCai1iSk2yez1TMtNxjKwK86rwSXeF_n2JAn-6KmiHKE4xyRCn9SHVKpFRk22pnySa4kQuG_BHvxsiCR_4N0"
  } : {
    publicKey: "BITM7o96dbfkHZ2N3IYSePIAo8ZizoLz0JvwY7zPV0Td8f0pX-naYcnyxOEN_VBdjXAq6v0gxsJ97Tx9yXmI8WU"
  }
};

export default config;



