import { useEffect, useState, useRef, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import mapboxgl from "mapbox-gl";
import { IconButton, useTheme } from "@mui/material";
import { useStation } from "../../../providers/StationProvider";
import Route from "../../../common/Route";
import { Location } from "@emberly/zenith-client";
import RouteDetailsCard from "./RouteDetailsCard";
import { MapIcons } from "../../../common/constants";
import { FitScreenOutlined as FitScreenIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      height: "100%",
      width: "100%",
      minHeight: "300px",
      "& .map": {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: theme.spacing(1)
      }
    },
    fitScreenButton: {
      position: "absolute",
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
      zIndex: 2,
      background: `${theme.palette.background.default} !important`,
      color: theme.palette.common.black,
    }
  })
);


export default function TaskMapSection(props) {
  const { route, routeRevision, location, path, details, className, fitScreenButton } = props;
  const { classes } = useStyles();
  const containerRef = useRef(null);
  const theme = useTheme()
  const { station } = useStation();
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);


  useEffect(() => {
    const component = containerRef.current;
    if (!component || !station) return;

    component.innerHTML = "";

    const m = new mapboxgl.Map({
      container: component, // container ID
      style: "mapbox://styles/mapbox/streets-v12", // style URL
      center: station?.location?.coordinates, // starting position [lng, lat]
      zoom: 10.5, // starting zoom
      attributionControl: false
    });


    ////

    m.loadImage(
      "/assets/icons/map/resolved.png",
      (error, image) => {
        m?.addImage(MapIcons.Pins.Resolved, image, { sdf: true });
      }
    );

    m.loadImage(
      "/assets/icons/map/moving.png",
      (error, image) => {
        m?.addImage(MapIcons.Pins.Moving, image, { sdf: true });
      }
    );

    m.loadImage(
      "/assets/icons/map/person.png",
      (error, image) => {
        m?.addImage(MapIcons.Pins.Person, image, { sdf: true });
      }
    );

    m.loadImage(
      "/assets/icons/map/home.png",
      (error, image) => {
        m?.addImage(MapIcons.Pins.Home, image, { sdf: true });
      }
    );

    m.loadImage(
      "/assets/icons/map/warehouse.png",
      (error, image) => {
        m?.addImage(MapIcons.Pins.Warehouse, image, { sdf: true });
      }
    );
    ////



    const ro = new ResizeObserver(() => {
      if (m || m.forceKilled !== true) {
        setTimeout(() => {
          try {
            m?.resize();
          } catch { }
        }, 0);
      }
    });

    ro.observe(component);

    m.on("load", () => {
      if (m.forceKilled === true) return;
      // Init source layer
      m.addSource("sub-routing-data", {
        type: "geojson",
        data: Route.EmptyGeoJson()
      });

      m.addSource("routing-data", {
        type: "geojson",
        data: Route.EmptyGeoJson()
      });

      m.addSource("waypoint-data", {
        type: "geojson",
        data: Route.EmptyGeoJson()
      });

      // Init rendering layer for line
      m.addLayer({
        type: "line",
        source: "sub-routing-data",
        id: "sub-routing-layer",
        paint: {
          "line-color": theme.palette.common.toRoute,
          "line-width": 3,
          "line-opacity": 1,
          "line-dasharray": [1, 2],
        },
        layout: {
          "line-cap": "round"
        }
      });

      m.addLayer({
        type: "line",
        source: "routing-data",
        id: "routing-layer",
        paint: {
          "line-color": theme.palette.common.inRoute,
          "line-width": 5,
          "line-opacity": 1
        },
        layout: {
          "line-cap": "round"
        }
      });

      m.addLayer({
        type: "symbol",
        source: "waypoint-data",
        id: "waypoint-layer",
        layout: {
          "icon-allow-overlap": true,
          "icon-image": ["get", "icon"],
          "icon-size": 0.65,
          // get the title name from the source"s "title" property
          "text-field": ["get", "title"],
          "text-font": [
            "Open Sans Semibold",
            "Arial Unicode MS Bold"
          ],
          "text-size": 12,
          "text-offset": [0, -1.25],
          "text-anchor": "bottom",

        },
        paint: {
          "icon-color": ["get", "color"]
        }
      });

      if (m.forceKilled !== true) {
        setMap(m);
      }
      // Add features
    });

    return () => {
      m.forceKilled = true;
      setMap(null);
      m?.remove();
      ro?.disconnect();
    };

  }, [theme, containerRef, station]);

  useEffect(() => {
    if (!!map && map.forceKilled !== true) {
      const l = !!location ? new Location(location) : null

      if (!!route) {
        route.loadRouting()
          .then(() => {
            if (map.forceKilled === true) return;

            map?.getSource("routing-data")?.setData(route.getRouteGeoJson());

            if (route.useStationLocation) {
              map?.getSource("sub-routing-data")?.setData(route.getSubRouteGeoJson());
            }

            const bounds = route.getBounds();

            if (!!bounds) {
              map?.fitBounds(bounds, { padding: 64, maxZoom: 14 });
              setBounds(bounds);
            }
          })
          .catch(err => console.log(err));

        map?.getSource("waypoint-data")?.setData(route.getWaypointsGeoJson(theme));

      } else if (l?.hasCoordinates) {

        const bounds = {
          center: l.coordinates,
          zoom: 15,
          padding: 32
        }

        map.flyTo(bounds);
        setBounds(bounds);


        map?.getSource("sub-routing-data")?.setData(Route.EmptyGeoJson());
        map?.getSource("routing-data")?.setData(Route.EmptyGeoJson());
        map?.getSource("waypoint-data")?.setData(Route.GeoJson([l.getGeoJson({ icon: MapIcons.Pins.Warehouse, color: theme.palette.success.dark })]));

      } else {

        const bounds = {
          center: station?.location?.coordinates,
          zoom: 13.5,
          padding: 32
        };

        // set map to station
        map.flyTo(bounds);
        setBounds(bounds);

        map?.getSource("sub-routing-data")?.setData(Route.EmptyGeoJson());
        map?.getSource("routing-data")?.setData(Route.EmptyGeoJson());
        map?.getSource("waypoint-data")?.setData(Route.EmptyGeoJson());
      }
    }
  }, [map, route, routeRevision, location, station]);

  const onFitScreen = useCallback(() => {
    if (!!map && map.forceKilled !== true && !!bounds) {
      if (!!bounds.center) {
        map?.flyTo(bounds);
      } else {
        map?.fitBounds(bounds, { padding: 64, maxZoom: 14 });
      }
    }
  }, [bounds, map])


  return (
    <div className={`${classes.root} ${className || ""}`}>
      <div ref={containerRef} className="map"></div>
      {!!route && !!details ? (
        <RouteDetailsCard in={!!route} route={route} path={path} />
      ) : null}
      {
        !!fitScreenButton ? (
          <IconButton className={classes.fitScreenButton} onClick={onFitScreen}>
            <FitScreenIcon />
          </IconButton>
        ) : null
      }
    </div>
  );
}