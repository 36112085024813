import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import { OrderEnumsLists } from "../../../../common/constants";
import EntityTable from "../../../common/tables/EntityTable";
import { ALL_OPEN_ORDERS } from "../../../../common/queries";

export default function OpenOrdersSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("orderFields:fieldNumber"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("orderFields:fieldMission"),
        flex: 0
      },
      {
        field: "customer.name",
        headerName: t("orderFields:fieldCustomer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "payment.method",
        type: "singleSelect",
        minWidth: 160,
        headerName: t("orderFields:fieldPaymentMethod"),
        valueGetter: (params) => params.row.payment.method,
        valueFormatter: (params) => t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[params.value]}`),
        valueOptions: OrderEnumsLists.PaymentMethod.map((_,i) => ({
          value: i,
          label: t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[i]}`),
          type: "number"
        })),
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("orderFields:fieldSumTotal"),
        valueGetter: (params) => Number(params.row.payment.calculatedTotal?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => `${FixNumber(params.row.payment.calculatedTotal?.value)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("orderFields:fieldDeductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        valueFormatter: (params) => FixNumber(params.value),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
      {
        field: "payment.sender.name",
        headerName: t("orderFields:fieldSender"),
        valueGetter: (params) => params.row.payment.sender?.name || "-",
        minWidth: 160,
        flex: 0.5
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./open/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);


  return (
    <EntityTable
      tableId="open_orders_section"
      type="Order"
      onRowClick={onRowClick}
      columns={columns}
      filter={ALL_OPEN_ORDERS}
      emptyStateTitle={t("openOrders:emptyStateTitle")}
      emptyStateDescription={t("openOrders:emptyStateDescription")}
      xs={12}
    />
  );
}

