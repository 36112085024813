import { createContext, useContext, useMemo } from "react";
import { useEntity } from "@emberly/zenith-client";
import { CanChangePaymentMethod } from "../common/orders";
import { useMission } from "./MissionProvider";

export const OrderContext = createContext();
export const useOrder = () => useContext(OrderContext);

export const OrderProvider = (props) => {
  const { entity: order, updateEntityField, deferUpdateEntityField } = useEntity()
  const { orders } = useMission();

  const open = CanChangePaymentMethod(order.payment);
  
  const hasValue = useMemo(() => {
    return !!order?.orderLines?.length || !!order?.deductible?.price?.value || !!orders.find(t => t.deductible?.order?.id === order?.id || t?.vatTransfer?.order?.id === order?.id);
  }, [order, orders]);

  return (
    <OrderContext.Provider
      value={{
        order,
        open,
        hasValue,
        updateOrderField: updateEntityField,
        deferUpdateOrderField: deferUpdateEntityField
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

