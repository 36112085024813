import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import moment from "moment/moment";
import EntityTable from "../../common/tables/EntityTable";
import { ActivityEnumsLists } from "../../../common/constants";
import { GetResourceLink } from "../../../common/activity";

export default function ActivityLogPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("common:tables:number"),
        flex: 0.5
      },
      {
        field: "created",
        headerName: t("common:tables:created"),
        type: "dateTime",
        valueFormatter: (params) => moment(params.value).format("LLL"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "activityCategory",
        headerName: t("activityLog:fieldCategory"),
        type: "singleSelect",
        flex: 0.75,
        minWidth: 120,
        valueFormatter: (params) => t(`activity:enums:category:${ActivityEnumsLists.Category[params.value]}`),
        valueOptions: ActivityEnumsLists.Category.map((_, value) => ({
          value,
          label: t(`activity:enums:category:${ActivityEnumsLists.Category[value]}`)
        }))
      },
      {
        field: "activityType",
        type: "singleSelect",
        headerName: t("activityLog:fieldType"),
        flex: 1,
        minWidth: 120,
        valueFormatter: (params) => t(`activity:enums:type:${ActivityEnumsLists.Type[params.value]}`),
        valueOptions: ActivityEnumsLists.Type.map((_, value) => ({
          value,
          label: t(`activity:enums:type:${ActivityEnumsLists.Type[value]}`),
        })).filter(t => !!t.label)
      },
      {
        field: "sender.name",
        headerName: t("activityLog:fieldSender"),
        valueGetter: (params) => params.row.sender?.name || "-",
        flex: 1,
        minWidth: 120,
      }

    ]
  }, [t]);


  const onRowClick = useCallback((params, ev) => {
    navigate(GetResourceLink(params.row), { relative: "path" });
  }, [navigate]);

  return (
    <ResponsivePage
      xs={12}
      xl={10}
      xxl={8}
      title={t("activityLog:title")}
    >

      <EntityTable
        tableId="activity_log"
        type="ActivityLog"
        columns={columns}
        onRowClick={onRowClick}
        emptyStateTitle={t("activityLog:emptyStateTitle")}
        emptyStateDescription={t("activityLog:emptyStateDescription")}
        sortDescendingByDefault
        serverSidePaging
        xs={12}
      />

    </ResponsivePage>
  );
}